import React, { useState } from "react";

function NavBarMobile() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMenu = () => {
        setIsMobileMenuOpen(false);
    };

    return (
        <nav className="navbar-mobile">
            {/* Botón de menú para mobile */}
            <button 
                className="mobile-menu-button" 
                onClick={toggleMobileMenu}
            >
                ☰
            </button>

            {/* Menú de enlaces para mobile */}
            <div className={`nav-links-mobile ${isMobileMenuOpen ? 'open' : ''}`}>
                <a href="#quienes-somos" onClick={closeMenu}>Quiénes Somos</a>
                <a href="#servicios" onClick={closeMenu}>Servicios</a>
                <a href="#porque-elegirnos" onClick={closeMenu}>¿Por qué elegirnos?</a>
                <a href="#contacto" onClick={closeMenu}>Contacto</a>
            </div>
        </nav>
    );
}

export default NavBarMobile;
