import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

function AccordionItem({ title, description, details, icon, isOpen, onToggle }) {
  return (
    <div className={`accordion-item ${isOpen ? 'open' : ''}`} onClick={onToggle}>
      <div className="accordion-header">
        <FontAwesomeIcon icon={icon} size="2x" color="#79AB92" style={{ marginRight: '5%' }} />
        <h3 style={{ margin: 0 }}>{title}</h3>
        <FontAwesomeIcon icon={faChevronDown} className={`chevron ${isOpen ? 'open' : ''}`} />
      </div>
      <p className="accordion-description"><strong>{description}</strong></p>
      <div className={`accordion-details ${isOpen ? 'open' : ''}`}>
        <p>{details}</p>
      </div>
    </div>
  );
}

export default AccordionItem;
