import React from 'react';

function Section({ id, title, children, image, reverse, centered }) {
  return (
    <section id={id} className={`section ${reverse ? 'section-reverse' : ''} ${centered ? 'section-centered' : ''}`}>
      {image && !reverse && <img src={image} alt={title} className="section-image" />}
      <div className="section-content">
        <h2 className="section-title">{title}</h2>
        {children}
      </div>
      {image && reverse && <img src={image} alt={title} className="section-image" />}
    </section>
  );
}

export default Section;
