import logoHeader from '../Assets/logo-header.png'; 
import React, { useState, useEffect } from "react";

function NavBar() {
    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = () => {
      if (window.scrollY > 30) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    
      }, []);

    return (
<nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
<img onClick={() => window.location = "/"} src={logoHeader} alt="Eximia Small Logo" className="logo-header" />
<a href="#quienes-somos">Quiénes Somos</a>
<a href="#servicios">Servicios</a>
<a href="#porque-elegirnos">¿Por qué elegirnos?</a>
<a href="#contacto">Contacto</a>
</nav>  );
}

export default NavBar;