import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
       
        <p>
          Síguenos en:
          <a href="https://www.instagram.com/eximiacyo?fbclid=IwY2xjawFr4G5leHRuA2FlbQIxMAABHZl5rFKD4eAs5bg0VYwmfmimtWM3HqUCRpFsxwyvbk5BfGEFcrWOodT02w_aem_KolLSa7XfKzEGNnZTvfbEw" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="1x" style={{ marginLeft: "10px" }} />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61566618724482" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} size="1x" style={{ marginLeft: "10px" }} />
          </a>
          <a href="https://www.linkedin.com/company/eximia-estudio-contable" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} size="1x" style={{ marginLeft: "10px" }} />
          </a>
        </p>
        <p>&copy; 2024 Eximia. Telefono: 098 439 874.</p>
      </div>
    </footer>
  );
}

export default Footer;
