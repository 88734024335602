import React, { useState } from "react";
import AccordionItem from "./AccordionItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoiceDollar,
  faUser,
  faFileContract,
  faBuilding,
  faTasks,
  faShieldAlt,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

function Services() {
  const [openIndexes, setOpenIndexes] = useState([]);

  const handleToggle = (index) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter(i => i !== index)); // Cierra el acordeón si ya está abierto
    } else {
      setOpenIndexes([...openIndexes, index]); // Abre el acordeón
    }
  };

  return (
    <div className="services-grid">
      <AccordionItem
        title="Impuestos"
        description="Brindamos asesoramiento fiscal para cumplir con las normativas vigentes y gestionamos la liquidación y pago de impuestos."
        details={
          <>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" /> Cálculos
              y estimaciones de impuestos vigentes.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" />{" "}
              Declaraciones juradas mensuales y anuales.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" /> Planificación Fiscal.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" />{" "}
              Beneficios fiscales.
            </p>
          </>
        }
        icon={faFileInvoiceDollar}
        isOpen={openIndexes.includes(0)} // Verifica si el índice está en el estado
        onToggle={() => handleToggle(0)} // Llama a la función al hacer clic
      />
      <AccordionItem
        title="Laboral"
        description="Ofrecemos servicios que incluyen la planificación, liquidación de sueldos y contribuciones, cumpliendo con la normativa y convenios. Gestionamos la nómina y mantenemos actualizada la planilla de trabajo."
        details={
          <>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" /> Liquidación de sueldos y pago al BPS.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" />{" "}
              Asesoramiento legal laboral.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" />{" "}
              Generación de contratos de trabajo, MTSS.
            </p>
          </>
        }
        icon={faUser}
        isOpen={openIndexes.includes(1)} // Verifica si el índice está en el estado
        onToggle={() => handleToggle(1)} // Llama a la función al hacer clic
      />
      <AccordionItem
        title="Contable"
        description="Procesamos y validamos información contable según la Ley de Sociedades, emitimos Estados Contables e Informes para bancos y la Auditoría Interna de la Nación."
        details={
          <>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" /> Registros contables.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" />{" "}
              Elaboración de informes.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" /> Control de contabilidad de la empresa.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" /> Estados Contables.
            </p>
          </>
        }
        icon={faFileContract}
        isOpen={openIndexes.includes(2)} // Verifica si el índice está en el estado
        onToggle={() => handleToggle(2)} // Llama a la función al hacer clic
      />
      <AccordionItem
        title="Sociedades"
        description="Te ayudamos a elegir la estructura empresarial que mejor se ajuste a las necesidades y proyecciones de tu negocio, proporcionando soluciones integrales y estratégicas para maximizar su rendimiento."
        details={
          <>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" />{" "}
              Unipersonales.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" />{" "}
              Sociedades de hecho.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" />{" "}
              Sociedades por Acciones Simplificadas - SAS.
            <p>
            </p>
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" />{" "}
              Sociedades de Responsabilidad Limitada - SRL.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" />{" "}
              Sociedades Anónimas - SA.
            </p>
          </>
        }
        icon={faBuilding}
        isOpen={openIndexes.includes(3)} // Verifica si el índice está en el estado
        onToggle={() => handleToggle(3)} // Llama a la función al hacer clic
      />
      <AccordionItem
        title="Gestión"
        description="Nos encargamos de realizar los trámites ante BPS, DGI y MTSS, garantizando el cumplimiento normativo y agilizando las gestiones necesarias para tu empresa."
        details={
          <>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" />{" "}
              Constancias.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" />{" "}
              Convenios.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" />{" "}
              Certificados.
            </p>
            <p>
              <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" />{" "}
              Actualización de datos.
            </p>
          </>
        }
        icon={faTasks}
        isOpen={openIndexes.includes(4)} // Verifica si el índice está en el estado
        onToggle={() => handleToggle(4)} // Llama a la función al hacer clic
      />
      <AccordionItem
        title="Seguros"
        description="  Ofrecemos soluciones aseguradoras que cubren diversas necesidades, brindando protección integral para tu empresa, bienes y empleados."
        details={
          <>
            <p>
  <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" /> Flota de vehículos.
</p>
<p>
  <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" /> Garantía de alquiler.
</p>
<p>
  <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" /> Hurto e incendio para empresas y hogares.
</p>
<p>
  <FontAwesomeIcon icon={faCheckCircle} color="#79AB92" /> Accidentes de trabajo.
</p>

          </>
        }
        icon={faShieldAlt}
        isOpen={openIndexes.includes(5)} // Verifica si el índice está en el estado
        onToggle={() => handleToggle(5)} // Llama a la función al hacer clic
      />
    </div>
  );
}

export default Services;
