import React, { useEffect, useState } from 'react';
import headerImage from '../Assets/header.png';
import logo from '../Assets/logo.png';

function Header() {
  return (
    <header className="App-header" style={{ backgroundImage: `url(${headerImage})` }}>
        <div className="header-overlay"></div>
        <div className="logo-mobile-container">
          <img src={logo} alt="Eximia Logo" className="logo" />
        </div>
        <div className="logo-container">
          <img src={logo} alt="Eximia Logo" className="logo" />
        </div>
      </header>
  );
}

export default Header;
