import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import "./App.css";
import Header from "./Components/Header";
import Section from "./Components/Section";
import Footer from "./Components/Footer";
import Services from "./Components/Services";
import NavBar from "./Components/NavBar";
import NavBarMobile from "./Components/NavBarMobile";

function App() {

  useEffect(() => {
      try{
        if (document?.getElementById("recaptcha-container")?.innerHTML?.trim() === "") {
          window?.grecaptcha?.render("recaptcha-container", {
            "sitekey": "6Ldw11gqAAAAAFJqmCdclFBuTTQ0iBrWraw7TU_J"
          });
        }
      }catch (e){
        console.log(e);
      }

    window?.addEventListener("resize", handleResize);
    return () => {
        window?.removeEventListener("resize", handleResize);
    };
  }, []);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    subject: "",
    email: "",
    message: ""
  });
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
};

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Evita el envío automático del formulario
    setLoading(true); // Establecer estado de cargando a verdadero

    // Obtener respuesta del reCAPTCHA
    const recaptchaResponse = window.grecaptcha.getResponse();
    if (!recaptchaResponse) {
      alert("Por favor, completa el reCAPTCHA.");
      setLoading(false); // Desactiva el estado de cargando si falla el reCAPTCHA
      return;
    }

    // Enviar los datos del formulario usando EmailJS
    emailjs
      .send(
        "service_9vrgdam", // Service_id de EmailJS
        "template_1d594qi", // Template_id de EmailJS
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          subject: formData.subject,
          email: formData.email,
          message: formData.message,
          "g-recaptcha-response": recaptchaResponse, // Incluye el reCAPTCHA response
        },
        "EZ-dR7Y1QEygtPl4e" // Public key de EmailJS
      )
      .then(
        (response) => {
          alert("Formulario enviado correctamente.");
          setFormData({
            firstName: "",
            lastName: "",
            subject: "",
            email: "",
            message: ""
          });
          window.grecaptcha.reset(); // Resetea el reCAPTCHA después de enviar
          setLoading(false); // Apaga el estado de cargando al completar
        },
        (error) => {
          alert("Hubo un error al enviar el formulario. Intenta nuevamente.");
          setLoading(false); // Apaga el estado de cargando al completar
        }
      );
  };

  return (
    <div className="App">
      {isMobile ? <NavBarMobile /> : <NavBar />}
      <Header />
      <div className="container">
        <Section id="quienes-somos" title="Quiénes Somos" centered>
          <p>
            En nuestra consultora, nos destacamos por ofrecer una solución
            integral que cubre todos los aspectos necesarios para tu empresa.
            Nos especializamos en una amplia gama de servicios, incluyendo
            impuestos, sueldos, contabilidad, seguros y gestiones ante
            organismos públicos. Lo que nos diferencia es nuestra capacidad para
            proporcionar un enfoque estratégico y personalizado, combinando
            experiencia y conocimientos para gestionar eficazmente cada área del
            negocio. Nuestro compromiso es ofrecer un servicio completo que
            optimiza y simplifica las operaciones empresariales, permitiéndote
            concentrarte en el crecimiento y éxito de tu empresa.
          </p>
        </Section>
        <Section id="servicios" title="">
          <h2 className="section-title" style={{ textAlign: "left" }}>
            Servicios que Brindamos
          </h2>
          <Services />
        </Section>

        <Section id="porque-elegirnos" className="porque-elegirnos-section">
          <div className="porque-elegirnos-content">
            <div className="text-content">
              <h2 className="section-title">¿Por qué elegirnos?</h2>
              <p>
                Nos destacamos por nuestro compromiso con el éxito financiero y
                el crecimiento sostenible de nuestros clientes. Con integridad,
                profesionalismo y un enfoque centrado en el cliente, ofrecemos
                soluciones contables personalizadas que brindan claridad,
                tranquilidad y resultados medibles. Además, nos adaptamos a las
                necesidades cambiantes del entorno empresarial, asegurando un
                acompañamiento constante en cada etapa del desarrollo de tu
                negocio.
              </p>
            </div>{" "}
            <img
              src={require("./Assets/por-que-elegirnos.png")}
              alt="Por Qué Elegirnos"
              className="section-image"
            />
            <div className="image-content"></div>
          </div>
        </Section>

        <Section id="contacto" className="contact-section">
          <div className="contact-container">
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3454.774747164059!2d-56.1905986842517!3d-34.90753028138071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f80584cb10569%3A0x48e1f0a3ae437e39!2sCarlos%20Quijano%201290%2C%2011100%20Montevideo%2C%20Uruguay!5e0!3m2!1ses!2suy!4v1692895364898!5m2!1ses!2suy"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Ubicación en Google Maps"
              ></iframe>
            </div>

            <div className="form-container">
              <h2 className="section-title">Contacto</h2>
              <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="first-name">Nombre</label>
                  <input
                    type="text"
                    id="first-name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="last-name">Apellido</label>
                  <input
                    type="text"
                    id="last-name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="subject">Asunto</label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Mensaje</label>
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    maxLength={512}
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div id="recaptcha-container"></div>
                <button type="submit" className="submit-btn" disabled={loading}>
                  {loading ? (
                      <div className="spinner"></div>
                  ) : (
                    "Enviar"
                  )}
                </button>
              </form>
            </div>
          </div>
        </Section>
      </div>
      <Footer />
    </div>
  );
}

export default App;
